import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare interface User {
  firstName: string;
  lastName: string;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserStateService {
  userToken: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  user: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor() { }
}
