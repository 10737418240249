<div class="mt-2" *ngIf="(stepsStore.steps$ | async) as step">
  <ul class="tabs-list u-unstyled-list mb-4">
    <li class="tabs-list__item">
      <a class="tabs-list__link" [ngClass]="{'active': step.step === steps.DOCUMENT}" aria-selected="true">
        <ng-template [ngIf]="stepsStore.step.documentType === documentTypes.DLFRONT">
          <ng-container *ngIf="stepsStore.step.allowDriversLicense || stepsStore.step.allowId" i18n="@@identityCard">Identity card</ng-container>
        </ng-template>

        <ng-container *ngIf="stepsStore.step.documentType !== documentTypes.DLFRONT" i18n="@@photoPassport">Photo passport</ng-container>
      </a>
    </li>
    <li class="tabs-list__item" *ngIf="stepsStore.step.selfieRequired">
      <a class="tabs-list__link" [ngClass]="{'active': step.step === steps.SELFIE}" aria-selected="false" i18n="@@photoSelfie">Photo selfie</a>
    </li>
    <li class="tabs-list__item" *ngIf="step.step === steps.OVERVIEW">
      <a class="tabs-list__link" [ngClass]="{'active': step.step === steps.OVERVIEW}" aria-selected="false" i18n="@@overview">Overview</a>
    </li>
  </ul>

  <ng-container *ngIf="!step.success">
    <ng-container [ngSwitch]="step.step">
      <!-- Passport/ID-Card -->
      <app-document-capture *ngSwitchCase="steps.DOCUMENT"></app-document-capture>
      <!-- Selfie -->
      <app-selfie-capture *ngSwitchCase="steps.SELFIE"></app-selfie-capture>
      <!-- Overview -->
      <app-overview *ngSwitchCase="steps.OVERVIEW"></app-overview>
    </ng-container>
  </ng-container>

  <app-success-capture *ngIf="step.success && !stepsStore.loading && !step.previewImage"></app-success-capture>

  <app-preview-document [previewDocument]="step.previewImage" *ngIf="step.previewImage && step.success && !stepsStore.loading"></app-preview-document>

  <app-loading *ngIf="stepsStore.loading"></app-loading>
</div>
