// eslint-disable member-ordering
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { Step } from '../../models/step';


@Injectable({
  providedIn: 'root'
})
export class StepsStoreService {
  private readonly isLoading = new BehaviorSubject<boolean>(false);
  private readonly steps = new BehaviorSubject<Step>({
    id: null,
    selfieRequired: false,
    documentType: null,
    step: 0,
    idSide: null,
    allowDriversLicense: false,
    allowId: false
  } as Step);

  // Expose the observable$ part of the steps subject (read only stream)
  readonly steps$ = this.steps.asObservable();

  get step(): Step {
    return this.steps.getValue();
  }

  set step(val: Step) {
    this.steps.next(val);
  }

  set next(nextStep: number) {
    this.steps.next({
      ...this.step,
      step: nextStep
    });
  }

  get loading(): boolean {
    return this.isLoading.getValue();
  }

  set loading(value: boolean) {
    this.isLoading.next(value);
  }

  reset() {
    this.steps.next({} as Step);
  }
}
