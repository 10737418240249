<div class="mx-2">
  <h3 class="h1-header h1-header_light h1-header_colored main-title" i18n="@@selectDocument">What type of document would you like to use?</h3>

  <div class="section_block" (click)="selectDocumentType(documentTypes.Passport)">
    <svg class="svg-icon svg-icon_colored mx-auto d-block" width="42" height="52">
      <use xlink:href="#passport-icon"></use>
    </svg>

    <p class="section_block__text" i18n="@@iUsePassport">I will use my passport</p>

    <button type="button" class="btn bc-btn btn-custom btn-block">
      <ng-container i18n="@@usePassport">Use passport</ng-container>
      <svg class="svg-icon float-right mt-1" width="6" height="12">
        <use xlink:href="#next-arrow-icon"></use>
      </svg>
    </button>
  </div>

  <div class="section_block" (click)="selectDocumentType(documentTypes.DLFRONT)" *ngIf="stepsStore.step.allowDriversLicense || stepsStore.step.allowId">
    <svg class="svg-icon svg-icon_colored mx-auto d-block" width="58" height="45">
      <use xlink:href="#id-card-icon"></use>
    </svg>

    <p class="section_block__text" i18n="@@iUseIdentityCard">I will use my Identity card</p>

    <button type="button" class="btn bc-btn btn-custom btn-block">
      <ng-container i18n="@@useID">Use Identity card</ng-container>
      <svg class="svg-icon float-right mt-1" width="6" height="12">
        <use xlink:href="#next-arrow-icon"></use>
      </svg>
    </button>
  </div>
</div>
