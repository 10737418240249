import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { StepsStoreService } from '../core/services';

import { Step } from '../models/step';
import { DocumentTypes, IdType } from '../models/documentType';
import { IdentificationSteps } from '../models/identificationSteps';

@Component({
  selector: 'app-select-document',
  templateUrl: './select-document.component.html',
  styleUrls: ['./select-document.component.scss']
})
export class SelectDocumentComponent {
  documentTypes = DocumentTypes;

  constructor(public stepsStore: StepsStoreService, private router: Router) { }

  selectDocumentType(type: DocumentTypes) {
    this.stepsStore.step = {
      ...this.stepsStore.step,
      step: IdentificationSteps.DOCUMENT,
      documentType: type,
      idSide: type === DocumentTypes.DLFRONT ?  IdType.FRONT : null
    } as Step;

    this.router.navigate(['/capture'], { queryParamsHandling: 'merge' });
  }
}
