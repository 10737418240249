import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { StepsStoreService } from '../core/services';


@Injectable({
  providedIn: 'root'
})
export class DocumentTypeGuard  {
  constructor(private router: Router, private stepsStore: StepsStoreService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.stepsStore.step.documentType) return true;

    this.router.navigate(['/select-document'], { queryParamsHandling: 'merge' });

    return false;
  }

}
