import { Component, Inject } from '@angular/core';
import { MITEK_MESSAGES_MANAGER } from './translation.injectors';
import { BcMessagesManager } from '../widgets';

@Component({
  selector: 'bc-translations',
  templateUrl: 'translations.component.html'
})
export class TranslationsComponent {
  constructor(
    @Inject(MITEK_MESSAGES_MANAGER) public mitekMessagesManager: BcMessagesManager,
  ) { }
}
