import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { IdentificationService } from '../core/services';
import { IdentificationDetails } from '../models/identificationDetails';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard  {
  constructor(private router: Router, private identificationService: IdentificationService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.identificationService.resolve(next, state)
      .then((res: IdentificationDetails) => {
        if (!res || !res.tokenEnabled) {
          this.router.navigate(['invalid-link']);

          return false;
        }

        return true;
      })
      .catch(() => {
        this.router.navigate(['invalid-link']);

        return false;
      });
  }
}
