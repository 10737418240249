export enum MitekFeedbackMessages {
  // Hint messages for SELFIE
  NO_FACE_FOUND = 'NO_FACE_FOUND',
  MISNAP_HEAD_SKEWED = 'MISNAP_HEAD_SKEWED',
  MISNAP_HEAD_TOO_CLOSE = 'MISNAP_HEAD_TOO_CLOSE',
  MISNAP_HEAD_TOO_FAR = 'MISNAP_HEAD_TOO_FAR',
  MISNAP_AXIS_ANGLE = 'MISNAP_AXIS_ANGLE',
  MISNAP_HEAD_OUTSIDE = 'MISNAP_HEAD_OUTSIDE',
  MISNAP_SMILE = 'MISNAP_SMILE',
  MISNAP_STAY_STILL = 'MISNAP_STAY_STILL',
  MISNAP_STOP_SMILING = 'MISNAP_STOP_SMILING',
  MISNAP_TOO_DARK = 'MISNAP_TOO_DARK',
  MISNAP_TOO_BLURRY = 'MISNAP_TOO_BLURRY',
  MISNAP_READY_POSE = 'MISNAP_READY_POSE',
  MISNAP_SUCCESS = 'MISNAP_SUCCESS',

// Hint messages for DL_FRONT, PASSPORT
  MITEK_ERROR_FOCUS = 'MITEK_ERROR_FOCUS',
  MITEK_ERROR_FOUR_CORNER = 'MITEK_ERROR_FOUR_CORNER',
  MITEK_ERROR_GLARE = 'MITEK_ERROR_GLARE',
  MITEK_ERROR_HORIZONTAL_FILL = 'MITEK_ERROR_HORIZONTAL_FILL',
  MITEK_ERROR_LOW_CONTRAST = 'MITEK_ERROR_LOW_CONTRAST',
  MITEK_ERROR_MIN_PADDING = 'MITEK_ERROR_MIN_PADDING',
  MITEK_ERROR_SKEW_ANGLE = 'MITEK_ERROR_SKEW_ANGLE',
  MITEK_ERROR_TOO_DARK = 'MITEK_ERROR_TOO_DARK',

  MITEK_ERROR_MRZ_MISSING = 'MITEK_ERROR_MRZ_MISSING',

  // Hint messages for PDF417_BARCODE
  CV_NO_BARCODE_FOUND = 'CV_NO_BARCODE_FOUND',

  // Hint messages for All
  CORRUPT_IMAGE = 'CORRUPT_IMAGE',
  IMAGE_SMALLER_THAN_MIN_SIZE = 'IMAGE_SMALLER_THAN_MIN_SIZE',
}

export enum MitekErrorStatuses {
  CONSTRAINT_NOT_SATISFIED = 'CONSTRAINT_NOT_SATISFIED',
  NO_CAMERA_FOUND = 'NO_CAMERA_FOUND',
  CAMERA_PERMISSION_DENIED = 'CAMERA_PERMISSION_DENIED',
  CAMERA_UNKNOWN_DEVICE_ISSUE = 'CAMERA_UNKNOWN_DEVICE_ISSUE',
  UNKNOWN_METHOD = 'UNKNOWN_METHOD',
  INVALID_COMMAND_SIGNATURE = 'INVALID_COMMAND_SIGNATURE',
  USER_MEDIA_NOT_SUPPORTED = 'USER_MEDIA_NOT_SUPPORTED',
  WASM_NOT_SUPPORTED = 'WASM_NOT_SUPPORTED',

  DEVICE_NOT_SUPPORTED = 'DEVICE_NOT_SUPPORTED',
  WEBGL_NOT_SUPPORTED = 'WEBGL_NOT_SUPPORTED',
  WEBGL_SHADER_NOT_SUPPORTED = 'WEBGL_SHADER_NOT_SUPPORTED',

  // blanco custom errors
  CAMERA_TIMEOUT = 'CAMERA_TIMEOUT',
  ERROR = 'ERROR'
}

export enum MitekResultStatus {
  Success = 'success',
  Failure = 'failure'
}
