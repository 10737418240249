<section>
  <ng-template [ngIf]="stepsStore.step.step !== steps.SELFIE">
    <p class="h4-header h4-header_light text-center" i18n="@@successPassport"
       *ngIf="stepsStore.step.documentType === documentTypes.Passport">
      A copy of your passport has been made successfully. You may now proceed to the next step.
    </p>

    <ng-template [ngIf]="stepsStore.step.documentType === documentTypes.DLFRONT">
      <p class="h4-header h4-header_light text-center" i18n="@@successIDFront"
         *ngIf="stepsStore.step.idSide === idTypes.FRONT">
        A copy of your document has been made successfully. You may now proceed to the next step.
      </p>
      <p class="h4-header h4-header_light text-center" i18n="@@successIDBack"
         *ngIf="stepsStore.step.idSide === idTypes.BACK">
        A copy of the back of your document has been made successfully. You may now proceed to the next step.
      </p>
    </ng-template>
  </ng-template>

  <p class="h4-header h4-header_light text-center" i18n="@@successSelfie"
     *ngIf="stepsStore.step.step === steps.SELFIE">
    Your selfie has been captured successfully. You may now proceed to the next step.
  </p>

  <img [src]="previewDocument.imageData" class="w-75 img-thumbnail mx-auto d-block">

  <hr>

  <div class="row mt-2 mb-4">
    <div class="col">
      <button type="button" (click)="retake()" i18n="@@retake"
              class="btn bc-btn btn-secondary btn-block">Re-take</button>
    </div>
    <div class="col">
      <button type="button" (click)="next()" i18n="@@proceed"
              class="btn bc-btn btn-custom btn-block">Proceed</button>
    </div>
  </div>
</section>
