import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { BcMessagesManager } from '../widgets';

import { DocumentService, Logger, StepsStoreService } from '../core/services';
import { IdentificationSteps } from '../models/identificationSteps';


@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent {
  loading: boolean;

  constructor(
    public bcMessagesManager: BcMessagesManager,
    public stepsStore: StepsStoreService,
    private documentService: DocumentService,
    private logger: Logger,
    private router: Router,
    private toastr: ToastrService) { }

  back() {
    this.stepsStore.next = this.stepsStore.step.selfieRequired ? IdentificationSteps.SELFIE : IdentificationSteps.DOCUMENT;
  }

  send() {
    this.toastr.info(this.bcMessagesManager.translations['LOADING'], this.bcMessagesManager.translations['LOADING_TITLE']);
    this.loading = true;
    this.documentService.sendComplete({}, this.stepsStore.step.id)
      .subscribe(result => {
        this.router.navigate(['/identification-complete'], { queryParamsHandling: 'merge' });
        this.loading = false;
      }, res => {
        this.logger.showError(res.error.errorMessage);
        this.loading = false;
      });
  }

}
