import { Component, OnInit } from '@angular/core';

import { StepsStoreService } from '../core/services';

import { DocumentTypes } from '../models/documentType';
import { IdentificationSteps } from '../models/identificationSteps';

@Component({
  selector: 'app-capture-screen',
  templateUrl: './capture-screen.component.html',
  styleUrls: ['./capture-screen.component.scss']
})
export class CaptureScreenComponent implements OnInit {
  documentTypes = DocumentTypes;

  steps = IdentificationSteps;

  constructor(public stepsStore: StepsStoreService) {}

  ngOnInit() {
    if (this.stepsStore.step.step === IdentificationSteps.START) {
      this.stepsStore.next = IdentificationSteps.DOCUMENT;
    }
  }
}
