// angular modules
import { ErrorHandler } from '@angular/core';
import { BugsnagService } from './bugsnag.service';
import { UserStateService } from '../../services';

export class BcErrorHandler implements ErrorHandler {

  constructor(private bugsnagService: BugsnagService,
              private userService: UserStateService) {
    this.bugsnagService.getConfig(this.userService.user).subscribe();
  }

  reportHandledError(error) {
    this.bugsnagService.getConfig(this.userService.user).subscribe(() =>
      this.bugsnagService.client.notify(error instanceof Error ? error : new Error(error))
    );
  }

  handleError(error) {
    const chunkFailedMessage = /Loading chunk .* failed/,
    unknownUrlMessage = /Error: Cannot match any routes. URL Segment/;

    const errorMessage = error.message || error.errorMessage;

    if (chunkFailedMessage.test(errorMessage)) {
      if (window.confirm('An error occurred while loading the page, please try again.')) {
        window.location.reload();
      }

      return;
    }
    if (unknownUrlMessage.test(errorMessage)) {
      this.reportHandledError(error);

      return;
    }

    this.bugsnagService.getConfig(this.userService.user).subscribe(() => this.bugsnagService.bugSnagHandler.handleError(error));
  }
}
