// angular modules
import { NgModule } from '@angular/core';
import { BcMessagesManager, BcCatchMessagesModule } from '../widgets';
import { MITEK_MESSAGES_MANAGER } from './translation.injectors';
import { TranslationsComponent } from './translations.component';

@NgModule({
  imports: [
    BcCatchMessagesModule
  ],
  declarations: [
    TranslationsComponent
  ],
  exports: [
    TranslationsComponent
  ],
  providers: [
    [{ provide: MITEK_MESSAGES_MANAGER, useClass: BcMessagesManager }],
  ]
})

export class TranslationsModule {}
