<bc-catch-messages [manager]="bcMessagesManager">
  <bc-message key="LOADING" i18n="@@pleaseWait">Please wait</bc-message>
  <bc-message key="LOADING_TITLE" i18n="@@loading">Loading</bc-message>

  <bc-message key="ERROR" i18n="@@unexpectedErrorShort">There was an unexpected error.</bc-message>
</bc-catch-messages>

<section *ngIf="(stepsStore.steps$ | async) as step">
  <div class="screen-title">
    <button type="button" class="screen-title__btn u-unstyled-btn" (click)="back()">
      <svg class="svg-icon" width="6" height="12">
        <use xlink:href="#back-arrow-icon"></use>
      </svg>
    </button>
    <p class="h3-header text-center" i18n="@@saveYourData">
      Save your data
    </p>
  </div>

  <p class="h3-header h3-header_light text-center my-5" i18n="@@overviewPhotosAndSend">
    We are about to save the following pictures that you have just taken.
  </p>

  <div class="pl-4 my-5 mx-auto d-block">
    <svg class="svg-icon success-icon float-left mr-1 mt-2" width="24" height="24">
      <use xlink:href="#success-icon"></use>
    </svg>
    <svg class="svg-icon svg-icon_colored float-left mr-3" width="62" height="40">
      <use xlink:href="#take-passport-id-card"></use>
    </svg>

    <p class="h4-header h4-header_light pt-2 align-bottom mb-0" i18n="@@photoOfYourDocument">
      Photo of your document
    </p>
  </div>

  <div class="pl-4 my-4 mx-auto d-block" *ngIf="step.selfieRequired">
    <svg class="svg-icon success-icon float-left mr-1 mt-2" width="24" height="24">
      <use xlink:href="#success-icon"></use>
    </svg>
    <svg class="svg-icon svg-icon_colored float-left mr-3" width="62" height="40">
      <use xlink:href="#make-selfie"></use>
    </svg>

    <p class="h4-header h4-header_light pt-2 align-bottom mb-0" i18n="@@photoOfYourself">Photo of yourself</p>
  </div>

  <div class="my-5 text-center">
    <button type="button" class="btn bc-btn btn-custom screen_btn" (click)="send()"  *ngIf="!loading">
      <ng-container *ngIf="!loading" i18n="@@send">Confirm and Save</ng-container>
      <ng-container *ngIf="loading" i18n="@@loading">Loading...</ng-container>
    </button>
  </div>
</section>
