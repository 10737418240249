<section>
  <div class="d-flex justify-content-center mt-5">
    <div class="spinner-grow text-info" role="status">
      <span class="sr-only" i18n="@@loading">Loading, please wait</span>
    </div>
    <div class="spinner-grow text-info" role="status">
      <span class="sr-only" i18n="@@loading">Loading, please wait</span>
    </div>
    <div class="spinner-grow text-info" role="status">
      <span class="sr-only" i18n="@@loading">Loading, please wait</span>
    </div>
  </div>
</section>
