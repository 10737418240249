<section>
  <div class="d-flex justify-content-center mt-5 mb-3">
    <svg class="svg-icon success-icon" width="80" height="80">
      <use xlink:href="#success-icon"></use>
    </svg>
  </div>

  <p class="h1-header h1-header_light text-center success mb-5" i18n="@@success">Success!</p>

  <ng-template [ngIf]="stepsStore.step.step !== steps.SELFIE">
    <p class="h4-header h4-header_light text-center" i18n="@@successPassport"
       *ngIf="stepsStore.step.documentType === documentTypes.Passport">
      A copy of your passport has been made successfully. You may now proceed to the next step.
    </p>

    <ng-template [ngIf]="stepsStore.step.documentType === documentTypes.DLFRONT">
      <p class="h4-header h4-header_light text-center" i18n="@@successIDFront"
         *ngIf="stepsStore.step.idSide === idTypes.FRONT">
        A copy of your document has been made successfully. You may now proceed to the next step.
      </p>
      <p class="h4-header h4-header_light text-center" i18n="@@successIDBack"
         *ngIf="stepsStore.step.idSide === idTypes.BACK">
        A copy of the back of your document has been made successfully. You may now proceed to the next step.
      </p>
    </ng-template>
  </ng-template>

  <p class="h4-header h4-header_light text-center" i18n="@@successSelfie"
     *ngIf="stepsStore.step.step === steps.SELFIE">
    Your selfie has been captured successfully. You may now proceed to the next step.
  </p>

  <div class="my-5 text-center">
    <button type="button" (click)="next()" i18n="@@next"
            class="btn bc-btn btn-custom screen_btn">Next</button>
  </div>
</section>
