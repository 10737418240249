import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { StepsStoreService } from '../core/services';

import { ConsentTypes } from '../models/consentTypes';
import { IdentificationDetails } from '../models/identificationDetails';
import { IdentificationSteps } from '../models/identificationSteps';
import { IdentificationService } from '../core/services/identification.service';

@Component({
  selector: 'app-landing-screen',
  templateUrl: './landing-screen.component.html',
  styleUrls: ['./landing-screen.component.scss']
})
export class LandingScreenComponent implements OnInit {
  consentTypes = ConsentTypes;
  defaultConsentMsg: boolean;
  terms: boolean;
  consent: boolean;

  details: IdentificationDetails;

  constructor(
    private router: Router,
    public stepsStore: StepsStoreService,
    private service: IdentificationService
  ) {}

  ngOnInit() {
    this.details = this.service.identificationDetails;
    if (!this.details.userConsent) this.details.userConsent = ConsentTypes.Default;
  }

  proceed() {
    this.stepsStore.next = IdentificationSteps.DOCUMENT;

    this.router.navigate(['/select-document'], { queryParamsHandling: 'merge' });
  }
}
