<div class="mx-2">
  <h3 class="h1-header h1-header_light h1-header_colored main-title">
    <ng-container i18n="@@welcome">Welcome </ng-container>
    {{ details.firstName }} {{ details.lastName }},
  </h3>
  <p class="h4-header h4-header_light" i18n="@@followingSteps">
    In order to properly collect all details needed, we will guide you through the following steps:
  </p>

  <div class="my-5">
    <svg class="svg-icon svg-icon_colored float-left mr-3" width="62" height="40">
      <use xlink:href="#take-passport-id-card"></use>
    </svg>

    <p i18n="@@takePhotoPassport">Take a photo of your passport or ID document</p>
  </div>

  <div class="my-5" *ngIf="stepsStore.step.selfieRequired">
    <svg class="svg-icon svg-icon_colored float-left mr-3" width="62" height="40">
      <use xlink:href="#make-selfie"></use>
    </svg>

    <p i18n="@@makeSelfie">Make a selfie so we can identify you</p>
  </div>

  <hr />

  <div class="field__item checkbox__field" *ngIf="details.userConsent === consentTypes.Default">
    <input class="checkbox__field"
           type="checkbox"
           id="agree_check"
           name='agree_check'
           [(ngModel)]="defaultConsentMsg">

    <label class="checkbox__label checkbox__label_smaller"
           for="agree_check" i18n="@@defaultConsentText">
      I consent to be contacted about my registration.
    </label>

    <span class="checkbox__check checkbox__check_smaller"></span>
  </div>

  <ng-template [ngIf]="details.userConsent === consentTypes.Custom">
    <div class="field__item checkbox__field">
      <input class="checkbox__field"
             type="checkbox"
             id="consent_terms"
             name='consent_terms'
             [(ngModel)]="terms">

      <label class="checkbox__label checkbox__label_smaller"
             for="consent_terms">
        <ng-container i18n="@@iAgreeOn">I agree with the </ng-container>
        <a [href]="details.termsAndConditionsUrl" target="_blank" class="link_colored"
           i18n="@@termsConditions">General Terms and Conditions</a>.
      </label>

      <span class="checkbox__check checkbox__check_smaller"></span>
    </div>

    <div class="field__item checkbox__field">
      <input class="checkbox__field"
             type="checkbox"
             id="consent_policy"
             name='consent_policy'
             [(ngModel)]="consent">

      <label class="checkbox__label checkbox__label_smaller"
             for="consent_policy">
        <ng-container i18n="@@userConsent">I consent to the storage of my data in accordance with the </ng-container>
        <a [href]="details.consentPolicyUrl" target="_blank"
           class="link_colored" i18n="@@policy">Privacy Statement</a>.
      </label>

      <span class="checkbox__check checkbox__check_smaller"></span>
    </div>
  </ng-template>

  <hr />

  <div class="my-2 text-center">
    <button type="button" class="btn bc-btn btn-custom screen_btn"
            [disabled]="details.userConsent === consentTypes.Default ? !defaultConsentMsg : !terms || !consent"
            (click)="proceed()" i18n="@@start">Start</button>
  </div>
</div>
