export enum DocumentTypes {
  DLFRONT = 'DL_FRONT',
  Passport = 'PASSPORT',
  Selfie = 'SELFIE'
}

export enum IdType {
  FRONT = 'IF',
  BACK = 'IB',
}

export enum DocumentTypesShortcat {
  Passport = 'P',
  ID = 'I'
}

export enum ImageTypes {
  Selfie = 'SF',
  Passport = 'PF',
  IdFront = 'IF',
  IdBack = 'IB',
}
