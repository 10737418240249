import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IdentificationService } from '../core/services/identification.service';
import { IdentificationDetails } from '../models/identificationDetails';

@Component({
  selector: 'app-identification-complete',
  templateUrl: './identification-complete.component.html',
  styleUrls: ['./identification-complete.component.scss']
})
export class IdentificationCompleteComponent implements OnInit {
  details: IdentificationDetails;

  constructor(
    private service: IdentificationService
  ) { }

  ngOnInit() {
    this.details = this.service.identificationDetails;
    const redirectUrl = this.details.redirectUrl;

    if (!redirectUrl) return;

    const redirectTimeout = 2000;
    setTimeout(() => {
      window.location.href = redirectUrl;
    }, redirectTimeout);
  }

}
