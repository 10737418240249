import { Component, Input } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { DocumentService, Logger, StepsStoreService } from '../core/services';

import { DocumentTypes, IdType } from '../models/documentType';
import { IdentificationSteps } from '../models/identificationSteps';


@Component({
  selector: 'app-preview-document',
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.scss']
})
export class PreviewDocumentComponent {
  @Input() previewDocument: any;
  documentTypes = DocumentTypes;
  idTypes = IdType;

  steps = IdentificationSteps;

  constructor(public stepsStore: StepsStoreService,
              private logger: Logger,
              private toastr: ToastrService,
              private documentService: DocumentService) { }

  next() {
    this.stepsStore.loading = true;
    this.documentService.saveDocument(this.stepsStore.step.previewImage, this.stepsStore.step.id)
      .subscribe(
        x => this.handleSuccess(),
        err => this.handleUnexpectedError(err)
      );
  }

  retake() {
    this.stepsStore.step.previewImage = null;
    this.stepsStore.step.success = false;
  }

  handleUnexpectedError(res): void {
    this.stepsStore.loading = false;
    this.logger.showError(res.error.errorMessage);
    this.retake();
  }

  handleSuccess(): any {
    this.stepsStore.loading = false;
    this.stepsStore.step.previewImage = null;
    if (this.stepsStore.step.idSide === IdType.FRONT) {
      this.stepsStore.step = {
        ...this.stepsStore.step,
        idSide: IdType.BACK
      };
    } else {
      this.stepsStore.next = (this.stepsStore.step.selfieRequired && this.stepsStore.step.step !== IdentificationSteps.SELFIE) ?
        IdentificationSteps.SELFIE : IdentificationSteps.OVERVIEW;
    }

    this.stepsStore.step.success = false;
  }
}
