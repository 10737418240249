// angular modules
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from '../../../../environments/environment';

// Import bugsnag-js and bugsnag-angular
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import Bugsnag from '@bugsnag/js';
import Client from '@bugsnag/core/types/client';

@Injectable({
  providedIn: 'root'
})
export class BugsnagService {
  client: Client;
  bugSnagHandler: BugsnagErrorHandler;
  user: any;

  constructor() { }

  getConfig(user$: Observable<any>) {
    return Observable.create((observer: Subject<any>) => {
      if (this.client && this.user && this.user.token) {
        observer.next(this.client);
        observer.complete();

        return;
      }

      user$.subscribe((user) => {
        this.user = user ? user : null;

        if (this.client && !this.user) {
          observer.next(this.client);
          observer.complete();

          return;
        }

        if (this.client) {
          this.client.setUser(this.user);
          this.bugSnagHandler = new BugsnagErrorHandler(this.client);

          observer.next(this.client);
          observer.complete();

          return;
        }

        this.client = Bugsnag.start({
          apiKey: environment.BUGSNAG_API,
          appVersion: environment.VERSION,
          releaseStage: environment.ENV,
          enabledReleaseStages: [ 'prd', 'acc', 'tst' ],
          user: this.user
        });
        this.bugSnagHandler = new BugsnagErrorHandler(this.client);

        observer.next(this.client);
        observer.complete();
      });
    });
  }
}
