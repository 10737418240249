import { Component, Inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BcMessagesManager } from '../widgets';
import { MITEK_MESSAGES_MANAGER } from '../translations/translation.injectors';
import { DocumentService, Logger, StepsStoreService } from '../core/services';
import { DocumentTypes, DocumentTypesShortcat, IdType, ImageTypes } from '../models/documentType';
import { MitekMode } from '../models/mitekMode';
import { MitekScienceCommands } from '../models/mitekScienceCommands';
import { MitekErrorStatuses, MitekFeedbackMessages, MitekResultStatus } from '../models/mitekStatuses';

import { MitekConfig } from '../shared/mitek-config';

declare global {
  interface Window {
    mitekScienceSDK: any;
  }
}

@Component({
  selector: 'app-document-capture',
  templateUrl: './document-capture.component.html',
  styleUrls: ['./document-capture.component.scss']
})
export class DocumentCaptureComponent {
  cameraDisplayStarted: boolean;
  cameraProcessingStarted: boolean;
  captureStarted: boolean;

  errorStatus: MitekErrorStatuses;
  errorMessages: string;
  MitekErrorStatuses = MitekErrorStatuses;

  processingNotStartedTimeout: any;
  autoCaptureTimeout: any;
  private readonly processingNotStartedTimeoutLength = 15000;
  private readonly autoCaptureTimeoutLength = 30000;

  private readonly hintsTimeout = 3000;

  constructor(
    @Inject(MITEK_MESSAGES_MANAGER) public mitekMessagesManager: BcMessagesManager,
    public stepsStore: StepsStoreService,
    private documentService: DocumentService,
    private logger: Logger,
    private router: Router,
    private toastr: ToastrService,
    private ngZone: NgZone
  ) {
  }

  back() {
    this.router.navigate(['/select-document'], {queryParamsHandling: 'merge'});
  }

  isPassportUpload(step) {
    return step.documentType === DocumentTypes.Passport;
  }

  isIdFrontSideUpload(step) {
    return step.documentType === DocumentTypes.DLFRONT && step.idSide === IdType.FRONT;
  }

  isIdBackSideUpload(step) {
    return step.documentType === DocumentTypes.DLFRONT && step.idSide === IdType.BACK;
  }

  clearTimers() {
    clearTimeout(this.autoCaptureTimeout);
    clearTimeout(this.processingNotStartedTimeout);
    this.autoCaptureTimeout = null;
    this.processingNotStartedTimeout = null;
  }

  captureDocument() {
    this.captureStarted = true;
    this.cameraDisplayStarted = false;
    this.cameraProcessingStarted = false;
    this.clearTimers();

    this.ngZone.runOutsideAngular(() => {
      this.initCapture(this.errorStatus ? MitekMode.MANUAL_CAPTURE : MitekMode.AUTO_CAPTURE);
    });
  }

  initCapture(mode: string) {
    this.errorStatus = null;
    this.errorMessages = null;

    if (this.isAutoCapture(mode)) {
      // may be needed to address new browser standards (explicit video play)
      const videoElement: HTMLMediaElement = document.querySelector('div video[autoplay="true"]');
      videoElement.play();

      if (!this.processingNotStartedTimeout) {
        this.processingNotStartedTimeout = setTimeout(() => {
          if (!this.cameraProcessingStarted || !this.cameraDisplayStarted) {
            window.mitekScienceSDK.cmd(MitekScienceCommands.SDK_STOP);

            this.ngZone.run(() => {
              this.captureStarted = false;
              this.errorStatus = MitekErrorStatuses.ERROR;
              this.errorMessages = this.mitekMessagesManager.translations['ERROR_CAMERA_STUCK'];
              this.logger.error(`cameraProcess: ${this.cameraProcessingStarted}, cameraDisplay: ${this.cameraProcessingStarted}`, 'Camera error');
            });
          }
        }, this.processingNotStartedTimeoutLength);
      }
    }

    window.mitekScienceSDK.cmd(MitekScienceCommands.CAPTURE_AND_PROCESS_FRAME, {
      ...MitekConfig,
      ...{
        mode: mode,
        documentType: this.stepsStore.step.documentType
      }
    });

    window.mitekScienceSDK.on(MitekScienceCommands.SDK_ERROR, err => {
      this.ngZone.run(() => {
        this.captureStarted = false;
        this.clearTimers();

        if (err) {
          this.errorStatus = err?.length && err[0].type;
          this.errorMessages = err.map(error => `${this.mitekMessagesManager.translations[error.type]}`).join(' ');
          this.logger.error(err.map(error => `${error.code} ${error.type}`).join(' '), MitekScienceCommands.SDK_ERROR);
        } else {
          this.errorStatus = MitekErrorStatuses.ERROR;
          this.errorMessages = this.mitekMessagesManager.translations[MitekErrorStatuses.ERROR];
          this.logger.error(this.errorStatus, MitekScienceCommands.SDK_ERROR);
        }
      });
    });

    if (this.isAutoCapture(mode)) {
      window.mitekScienceSDK.on(MitekScienceCommands.CAMERA_DISPLAY_STARTED, (result) => {
        this.ngZone.run(() => {
          this.cameraDisplayStarted = true;

          this.toastr.info(
            this.mitekMessagesManager.translations['LOADING'],
            this.mitekMessagesManager.translations['LOADING_TITLE'],
            {closeButton: true, positionClass: 'toast-top-center'}
          );
        });

        window.mitekScienceSDK.cmd(MitekScienceCommands.SHOW_HINT, this.mitekMessagesManager.translations['MITEK_INITIAL_HINT']);

        setTimeout(() => {
          window.mitekScienceSDK.cmd(MitekScienceCommands.HIDE_HINT);
        }, this.hintsTimeout);
      });
    }

    window.mitekScienceSDK.on(MitekScienceCommands.FRAME_PROCESSING_FEEDBACK, (result) => {
      const recentHint = result.key;

      if (recentHint) {
        const hintMessages = recentHint === MitekFeedbackMessages.MITEK_ERROR_FOUR_CORNER ?
          this.mitekMessagesManager.translations['MITEK_FOUR_CORNER_MESSAGE'] :
          this.mitekMessagesManager.translations[recentHint];
        window.mitekScienceSDK.cmd(MitekScienceCommands.SHOW_HINT, hintMessages);

        if (!this.isAutoCapture(mode)) {
          this.ngZone.run(() => {
            this.captureStarted = false;
            this.toastr.info(hintMessages || recentHint, '', {closeButton: true, positionClass: 'toast-top-center'});
          });
        }
      }
    });

    window.mitekScienceSDK.on(MitekScienceCommands.FRAME_PROCESSING_STARTED, () => {
      this.ngZone.run(() => {
        this.cameraProcessingStarted = true;

        this.toastr.clear();
      });

      if (!this.autoCaptureTimeout) {
        this.autoCaptureTimeout = setTimeout(() => this.stopAutoCapture(), this.autoCaptureTimeoutLength);
      }
    });

    window.mitekScienceSDK.on(MitekScienceCommands.FRAME_CAPTURE_RESULT, this.frameCaptureResult.bind(this));
  }

  private isAutoCapture(mode: string) {
    return mode === MitekMode.AUTO_CAPTURE;
  }

  private stopAutoCapture() {
    window.mitekScienceSDK.cmd(MitekScienceCommands.SDK_STOP);

    this.ngZone.run(() => {
      this.captureStarted = false;
      this.errorStatus = MitekErrorStatuses.CAMERA_TIMEOUT;
      this.errorMessages = this.mitekMessagesManager.translations['CAMERA_TIMEOUT'];
    });
  }

  private frameCaptureResult(result) {
    this.ngZone.run(() => {
      this.captureStarted = false;
      clearTimeout(this.autoCaptureTimeout);
      clearTimeout(this.processingNotStartedTimeout);

      const resultStatus = result.response.status;
      if (resultStatus === MitekResultStatus.Failure) return;

      this.stepsStore.step.previewImage = result.response.image;

      const documentType = result.response.docType;

      switch (documentType) {
        case DocumentTypes.Passport:
          this.stepsStore.step.previewImage = {
            documentType: DocumentTypesShortcat.Passport,
            imageType: ImageTypes.Passport,
            imageData: result.response.imageData
          };
          break;

        case DocumentTypes.DLFRONT:
          this.stepsStore.step.previewImage = {
            documentType: DocumentTypesShortcat.ID,
            imageType: this.stepsStore.step.idSide,
            imageData: result.response.imageData
          };
          break;

        default:
          this.logger.error(`${documentType} type isn't implemented`);
          console.error(`${documentType} type isn't implemented`);
      }

      this.stepsStore.step.success = true;
    });

    window.mitekScienceSDK.cmd(MitekScienceCommands.SDK_STOP);
  }
}
