import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CaptureScreenComponent } from './capture-screen/capture-screen.component';
import { LandingScreenComponent } from './landing-screen/landing-screen.component';
import { SelectDocumentComponent } from './select-document/select-document.component';
import { IdentificationCompleteComponent } from './identification-complete/identification-complete.component';

import { AuthenticationGuard } from './guards/authentication.guard';
import { DocumentTypeGuard } from './guards/document-type.guard';

import { InvalidUrlComponent } from './invalid-url/invalid-url.component';
import { LayoutComponent } from './core/components/layout/layout.component';
import { IdentificationService } from './core/services';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      identificationDetails: IdentificationService
    },
    children: [
      {
        path: '',
        component: LandingScreenComponent
      },
      {
        path: 'capture',
        canActivate: [DocumentTypeGuard],
        component: CaptureScreenComponent
      },
      {
        path: 'select-document',
        component: SelectDocumentComponent
      },
      {
        path: 'identification-complete',
        component: IdentificationCompleteComponent
      },
    ]
  },
  {
    path: 'invalid-link',
    component: InvalidUrlComponent
  },
  {
    path: '**',
    component: InvalidUrlComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
