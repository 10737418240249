import { NgModule } from '@angular/core';

import { BcMessageComponent } from './bc.catch-message.component';
import { BcMessagesComponent } from './bc.catch-messages.component';

@NgModule({
  declarations: [
    BcMessageComponent,
    BcMessagesComponent
  ],
  exports: [
    BcMessageComponent,
    BcMessagesComponent
  ]
})
export class BcCatchMessagesModule { }
