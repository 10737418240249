<svg style="display: none;" xmlns="http://www.w3.org/2000/svg">
  <symbol id="take-passport-id-card" viewBox="0 0 62 40">
    <g fill="none" fill-rule="nonzero">
      <path fill="#333" d="M28.267 10.467h27.6c1.866 0 4.666 1.733 4.666 4.666v18.4C60.4 36.6 58 38.2 55.867 38.2h-27.6c-2.534 0-4.667-1.867-4.667-4.533v-18.4c0-2.4 2.267-4.8 4.667-4.8zM62 15.133C62 11.267 59.2 9 55.867 9h-27.6c-3.334 0-6.134 2.8-6.134 6.133v18.4c0 3.334 2.8 6.134 6.134 6.134h27.6c3.333 0 6.133-2.8 6.133-6.134v-18.4z"/>
      <path fill="currentColor" d="M42.933 21c0 .533.267.933.534.933h11.866c.267 0 .534-.4.534-.933s-.267-.933-.534-.933h-12c-.133 0-.4.4-.4.933M36.267 15.4c0 .533.4.933.8.933h18.666c.4 0 .8-.4.8-.933s-.4-.933-.8-.933H37.067c-.4 0-.8.4-.8.933"/>
      <g>
        <path fill="#FFF" d="M29.2 33.733v-27.6c0-1.866-1.733-4.666-4.667-4.666h-18.4C3.067 1.467 1.467 4 1.467 6.133v27.6C1.467 36.267 3.333 38.4 6 38.4h18.4c2.4-.133 4.8-2.267 4.8-4.667zm-15.492-19.8L21.067 17"/>
        <path fill="#333" d="M29.2 33.733v-27.6c0-1.866-1.733-4.666-4.667-4.666h-18.4C3.067 1.467 1.467 4 1.467 6.133v27.6C1.467 36.267 3.333 38.4 6 38.4h18.4c2.4-.133 4.8-2.267 4.8-4.667zM24.533 0c3.867 0 6.134 2.8 6.134 6.133v27.6c0 3.334-2.8 6.134-6.134 6.134h-18.4C2.8 39.867 0 37.067 0 33.733v-27.6C0 2.8 2.8 0 6.133 0h18.4z"/>
        <path fill="currentColor" d="M24.667 28h-18.4c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h18.4c.4 0 .8.267.8.8 0 .4-.4.8-.8.8zM24.667 32.8h-18.4c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h18.4c.4.133.8.4.8.8s-.4.8-.8.8z"/>
        <path fill="#1D1D1B" d="M15.067 7.6c-3.734 0-6.8 3.067-6.8 6.8 0 3.733 3.066 6.8 6.8 6.8 3.733 0 6.8-3.067 6.8-6.8 0-3.733-3.067-6.8-6.8-6.8m0 14.533c-4.267 0-7.734-3.466-7.734-7.6C7.333 10.4 10.8 6.8 15.067 6.8c4.266 0 7.6 3.333 7.6 7.6 0 4.267-3.467 7.733-7.6 7.733"/>
        <path fill="#1D1D1B" d="M14.667 21.2h.933V6.8h-.933z"/>
        <path fill="#1D1D1B" d="M8.533 11.333h12.534V10.4H8.533zM8 14.4h14.4v-.933H8zM8.533 17.467H21.6v-.934H8.533z"/>
        <path fill="#1D1D1B" d="M14.8 7.6c-1.867 0-3.467 3.067-3.467 6.8 0 3.733 1.6 6.8 3.467 6.8s3.467-3.067 3.467-6.8c0-3.733-1.467-6.8-3.467-6.8m-4.4 6.933c0-4.266 2-7.733 4.4-7.733 2.4 0 4.4 3.333 4.4 7.733 0 4.4-1.867 7.6-4.4 7.6-2.533 0-4.4-3.333-4.4-7.6z"/>
      </g>
    </g>
  </symbol>

  <symbol id="make-selfie" viewBox="0 0 52 45">
    <g fill="none" fill-rule="evenodd">
      <path fill="#333" fill-rule="nonzero" d="M49.927 0C51.473.54 52 1.276 52 2.896v32.39C52 36.952 50.966 38 49.322 38H33.3c-1.624 0-2.663-1.05-2.663-2.693V12.705c-.597-.224-.656-.345-.628-1.266.012-.385.162-.557.614-.697v-.587c-.754-.136-.613-.737-.612-1.266 0-.371.156-.534.617-.694 0-.059.007-.12.008-.183v-.373c-.598-.233-.655-.35-.628-1.266.012-.386.16-.558.628-.706V2.993c0-.284-.014-.574.03-.852.172-1.086.894-1.845 1.96-2.09.05-.014.1-.03.148-.051h17.153zm.808 5.089H31.918V31.65h18.804V20.91h-2.035l-1.04 2.628c-.119.302-.283.532-.653.532-.37 0-.532-.22-.651-.528-.31-.802-.637-1.596-.943-2.398-.07-.186-.164-.258-.366-.25-.377.018-.753.007-1.13.006-.488 0-.7-.216-.7-.714V7.68c0-.5.21-.713.7-.713h6.832l-.001-1.878zm-18.841-1.31h18.85V2.715c0-.978-.472-1.45-1.446-1.45H33.342c-.104 0-.21-.003-.314.005a1.225 1.225 0 0 0-1.131 1.157c-.016.442-.003.884-.003 1.35zm18.84 29.178h-7.69c1.06 1.35 1.062 2.227.023 3.743.017.01.032.029.049.029 2.156 0 4.312.01 6.471 0a1.166 1.166 0 0 0 1.143-1.1c.032-.887.006-1.772.006-2.672h-.001zm-18.84-.013v2.407c0 .886.494 1.385 1.382 1.386h6.369c-.566-.57-.839-1.184-.839-1.915 0-.73.303-1.33.807-1.875l-7.72-.003zm10.681 1.875a1.261 1.261 0 0 0-1.27-1.252c-.694.007-1.25.58-1.243 1.28.007.699.575 1.26 1.27 1.253.333-.003.651-.14.885-.38.233-.24.362-.564.358-.9z"/>
      <path fill="#FFF" d="M32 5h18.7v26H32z"/>
      <path fill="#000" fill-rule="nonzero" d="M42.008 2.002c.458 0 .915-.003 1.372 0 .368.003.618.208.62.495.002.288-.249.5-.613.5-.923.004-1.845.004-2.768 0-.367 0-.617-.207-.619-.496-.002-.288.246-.5.612-.5.464-.003.929 0 1.396 0z"/>
      <path fill="#000" fill-rule="nonzero" d="M38.993 2.992a4.823 4.823 0 0 1-.4 0c-.333-.027-.587-.235-.593-.485-.005-.26.26-.478.608-.497.244-.012.489-.013.733-.003.361.001.655.218.659.488.005.276-.278.489-.674.503-.11.004-.222 0-.333 0v-.006z"/>
      <g stroke="currentColor" stroke-width="1.2">
        <path d="M42.839 18.735c.795-.851 1.407-2.223 1.407-3.573 0-2.074-1.336-3.162-2.886-3.162-1.552 0-2.878 1.087-2.878 3.162 0 1.356.607 2.732 1.411 3.582.314.85-.247 1.167-.364 1.211C37.905 20.563 36 21.67 36 22.763v.409C36 24.66 38.791 25 41.375 25c2.588 0 5.344-.339 5.344-1.828v-.409c0-1.126-1.914-2.225-3.618-2.808-.078-.026-.569-.255-.262-1.22z"/>
      </g>
      <g fill="#FFF" stroke="currentColor" stroke-width="1.8">
        <path d="M22.76 24.723c2.531-2.618 4.476-6.84 4.476-10.992C27.236 7.348 22.987 4 18.054 4c-4.936 0-9.158 3.346-9.158 9.73 0 4.17 1.931 8.406 4.491 11.022 1 2.617-.786 3.589-1.159 3.726C7.061 30.346 1 33.753 1 37.117v1.258C1 42.955 9.882 44 18.103 44c8.235 0 17.004-1.042 17.004-5.623v-1.26c0-3.465-6.092-6.845-11.513-8.64-.247-.08-1.81-.785-.833-3.754z"/>
      </g>
    </g>
  </symbol>

  <symbol id="selfie-long-icon" viewBox="0 0 69 122">
    <g fill="none" fill-rule="evenodd" transform="translate(-1)">
      <g transform="rotate(-90 61.22 60.309)">
        <rect width="120.235" height="68.419" x=".412" y=".302" fill="#4C4A4A" rx="10"/>
        <path fill="#000" d="M11.235.302h45.853l14.97 68.419H11.236c-5.523 0-10-4.477-10-10V10.3c0-5.522 4.477-10 10-10z"/>
        <rect width="5.765" height="14.838" x="110.765" y="26.68" fill="#666567" rx="2.882"/>
        <rect width="92.235" height="60.176" x="15.235" y="4.423" fill="#889497" rx="4"/>
        <path fill="#4C4A4A" d="M11.235.302h45.523l15.3 68.419H11.236c-5.523 0-10-4.477-10-10V10.3c0-5.522 4.477-10 10-10z" opacity=".289"/>
        <rect width="5.765" height="5.77" x="5.765" y="31.214" fill="#424142" rx="2.882"/>
      </g>
      <g transform="translate(41 39)">
        <mask id="a" fill="#fff">
          <path d="M.59.063h19.825v44.619H.59z"/>
        </mask>
        <path fill="#1C1C1C" d="M.59 4.468c3.092-5.19 20.14-9.371 19.82 9.292-.281 16.413-9.328 18.657-6.172 24.926 1.347 2.672 6.172-1.593 6.172-1.593s-1 7.589-7.68 7.589c-6.681 0-7.511-6.538-7.018-12.265L.59 4.467z" mask="url(#a)"/>
      </g>
      <path fill="#CBCBCA" d="M35 92.998l7-5.999S40.992 85.537 40.56 81H29.44c-.432 4.537-1.44 6-1.44 6l7 5.998V93v-.002z"/>
      <path fill="#666567" d="M40.841 81H29.16c-.048.534-.102 1.02-.159 1.481C30.756 84.122 32.793 85 35 85s4.244-.878 6-2.52a37.988 37.988 0 01-.159-1.48" opacity=".151"/>
      <path fill="#CBCBCA" d="M25 71.573s-2.189 2.05-3.252-2.833c-1.136-5.22-1.096-8.054.941-8.651 1.303-.383 1.677.596 1.677.596L25 71.573z"/>
      <path fill="#CBCBCA" d="M36 84c6.79 0 12-7.498 12-19.917C48 48.598 43.69 45 36 45c-7.689 0-12 3.598-12 19.083C24 76.503 29.21 84 36 84"/>
      <path fill="#CBCBCA" d="M47 71.573s1.642 2.05 2.44-2.833c.85-5.22.821-8.054-.706-8.651-.977-.383-1.258.596-1.258.596L47 71.573z"/>
      <path fill="#1C1C1C" d="M34.608 43C20.576 43 19.493 56.416 20.14 61.253 20.465 63.68 22.417 66 22.417 66s.514-6.545 4.863-8.412c4.35-1.867 10.704-.862 13.78-5.39 3.613 5.361 5.222 7.618 5.927 13.802 4.01-4.1 7.19-22.857-12.379-23"/>
      <g transform="translate(12 87)">
        <mask id="b" fill="#fff">
          <path d="M0 .409h44.761v18.433H.001z"/>
        </mask>
        <path fill="#FFF" d="M42.508 9.5a4.418 4.418 0 00-1.97-2.725L30.23.41l-7.85 2.85L14.533.41 4.226 6.775A4.418 4.418 0 002.254 9.5L0 18.842h44.761L42.508 9.5z" mask="url(#b)"/>
      </g>
      <path fill="#F0F0F0" d="M43.038 85.168l-.909-.142-6.628 4.4L28.876 85l-.912.168L25 89.898s3.36 7.591 10.501 2.12C42.641 97.489 46 89.898 46 89.898l-2.962-4.73z"/>
      <path fill="#FFF" d="M36 94.5a.5.5 0 11-1 0 .5.5 0 011 0"/>
      <path fill="#9B9B9B" d="M36 98.499a.5.5 0 11-1 .002.5.5 0 011-.002m0 2a.5.5 0 11-1 .002.5.5 0 011-.002m0 3.001a.5.5 0 11-.999.002.5.5 0 01.999-.003"/>
      <ellipse cx="35.534" cy="62.5" stroke="#D8D7D8" stroke-width="2" rx="24.746" ry="36.5"/>
    </g>
  </symbol>

  <symbol id="passport-icon" viewBox="0 0 41 52">
    <path fill="#FFF" d="M38.71 43.853V7.973c0-2.426-2.297-6.066-6.186-6.066H8.13c-4.066 0-6.187 3.293-6.187 6.066v35.88c0 3.294 2.475 6.067 6.01 6.067h24.393c3.182-.173 6.364-2.947 6.364-6.067zm-20.537-25.74l9.755 3.987"/>
    <path fill="#333" d="M38.71 43.853V7.973c0-2.426-2.297-6.066-6.186-6.066H8.13c-4.066 0-6.187 3.293-6.187 6.066v35.88c0 3.294 2.475 6.067 6.01 6.067h24.393c3.182-.173 6.364-2.947 6.364-6.067zM32.525 0c5.126 0 8.13 3.64 8.13 7.973v35.88c0 4.334-3.711 7.974-8.13 7.974H8.13c-4.418 0-8.13-3.64-8.13-7.974V7.973C0 3.64 3.712 0 8.131 0h24.393z"/>
    <path fill="currentColor" d="M32.7 36.4H8.309c-.53 0-1.06-.52-1.06-1.04s.53-1.04 1.06-1.04H32.7c.53 0 1.06.347 1.06 1.04 0 .52-.53 1.04-1.06 1.04zm0 6.24H8.309c-.53 0-1.06-.52-1.06-1.04s.53-1.04 1.06-1.04H32.7c.53.173 1.06.52 1.06 1.04s-.53 1.04-1.06 1.04z"/>
    <path fill="#1D1D1B" d="M19.974 9.88c-4.95 0-9.015 3.987-9.015 8.84s4.066 8.84 9.015 8.84c4.95 0 9.015-3.987 9.015-8.84s-4.066-8.84-9.015-8.84m0 18.893c-5.656 0-10.252-4.506-10.252-9.88 0-5.373 4.596-10.053 10.252-10.053 5.656 0 10.075 4.333 10.075 9.88 0 5.547-4.596 10.053-10.075 10.053"/>
    <path fill="#1D1D1B" d="M19.444 27.56h1.237V8.84h-1.237z"/>
    <path fill="#1D1D1B" d="M11.313 14.733h16.615V13.52H11.313zm-.707 3.987h19.09v-1.213h-19.09zm.707 3.987h17.322v-1.214H11.313z"/>
    <path fill="#1D1D1B" d="M19.62 9.88c-2.474 0-4.595 3.987-4.595 8.84s2.12 8.84 4.595 8.84c2.475 0 4.596-3.987 4.596-8.84s-1.944-8.84-4.596-8.84m-5.833 9.013c0-5.546 2.652-10.053 5.833-10.053 3.182 0 5.833 4.333 5.833 10.053 0 5.72-2.474 9.88-5.833 9.88-3.358 0-5.833-4.333-5.833-9.88z"/>
  </symbol>

  <symbol id="id-card-icon" viewBox="0 0 58 45">
    <path fill="#333" d="M9.223 2.996h39.423c2.666 0 6.666 2.469 6.666 6.646v26.205c-.19 4.368-3.619 6.646-6.666 6.646H9.223c-3.618 0-6.665-2.658-6.665-6.456V9.832c0-3.418 3.237-6.836 6.665-6.836zm48.184 6.646c0-5.506-4-8.734-8.761-8.734H9.223c-4.76 0-8.76 3.987-8.76 8.734v26.205c0 4.747 4 8.735 8.76 8.735h39.423c4.761 0 8.76-3.988 8.76-8.735V9.642z"/>
    <path fill="currentColor" d="M30.173 17.998c0 .76.38 1.329.761 1.329h16.95c.381 0 .762-.57.762-1.33 0-.759-.38-1.329-.762-1.329h-17.14c-.19 0-.571.57-.571 1.33m-9.523-7.976c0 .76.572 1.33 1.143 1.33h26.663c.57 0 1.142-.57 1.142-1.33 0-.76-.571-1.329-1.142-1.329H21.793c-.571 0-1.143.57-1.143 1.33"/>
  </symbol>

  <symbol id="next-arrow-icon" viewBox="0 0 8 14">
    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 13l6-5.892L1 1" opacity=".4"/>
  </symbol>
  <symbol id="back-arrow-icon" viewBox="0 0 8 14">
    <path fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M7 1L1 6.9 7 13" opacity=".4"/>
  </symbol>

  <symbol id="success-icon" viewBox="0 0 80 80">
    <circle cx="40" cy="40" r="40" fill="currentColor"/>
    <path fill="#FFF" fill-rule="nonzero" d="M62.615 31.253L36.701 56.487a1.826 1.826 0 01-2.548-.01l-13.63-13.488a1.746 1.746 0 01.01-2.502l3.411-3.321a1.826 1.826 0 012.548.01l8.978 8.884 21.224-20.666a1.826 1.826 0 012.548.01l3.383 3.348a1.745 1.745 0 01-.01 2.501z"/>
  </symbol>
  <symbol id="warning-icon" viewBox="0 0 76 67">
    <g fill="none" fill-rule="evenodd">
      <path fill="currentColor" d="M41 39.422V27.366C41 26.058 39.67 25 38.025 25h-1.062C35.33 25 34 26.058 34 27.366v12.056c0 1.308 1.33 2.378 2.963 2.378h1.062C39.67 41.8 41 40.73 41 39.422m0 11.2v-1.736c0-1.32-1.33-2.378-2.975-2.378h-1.062c-1.633 0-2.963 1.058-2.963 2.378v1.736C34 51.942 35.33 53 36.963 53h1.062C39.67 53 41 51.942 41 50.622"/>
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" d="M39.728 3.735l33.337 57.259A2 2 0 0171.336 64H4.663a2 2 0 01-1.729-3.006l33.337-57.26a2 2 0 013.457 0z"/>
    </g>
  </symbol>
  <symbol id="expired-icon" viewBox="-10 0 94 74">
    <g transform="translate(2 2)" fill="none" fill-rule="evenodd">
      <path d="M68.571 35c0-19.33-15.35-35-34.285-35C15.35 0 0 15.67 0 35s15.35 35 34.286 35" stroke="#2A2A32" stroke-width="3" stroke-linecap="round"/>
      <path stroke="#2A2A32" stroke-width="3" stroke-linejoin="round" d="M60.571 37.333L80 70H41.143z"/>
      <path stroke="#2A2A32" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" d="M49.001 37.084H34.286v-20.75M61.143 47.833v10.355"/>
      <ellipse fill="#2A2A32" cx="61.143" cy="63.583" rx="1.714" ry="1.75"/>
    </g>
  </symbol>

  <symbol id="photo-icon" viewBox="0 0 18 14">
    <path d="M16.5 13.5H2.236c-.823 0-1.493-.67-1.493-1.492v-9.06c0-.823.67-1.492 1.493-1.492h5.12V1.29c0-.711.58-1.29 1.291-1.29h4.893c.712 0 1.291.579 1.291 1.29v.166h1.67c.823 0 1.492.67 1.492 1.492v9.06c0 .823-.67 1.492-1.493 1.492zM2.236 2.03a.919.919 0 00-.918.918v9.06a.92.92 0 00.918.918H16.5a.919.919 0 00.918-.918v-9.06a.919.919 0 00-.918-.917h-1.957a.287.287 0 01-.287-.288V1.29a.716.716 0 00-.716-.716H8.647a.716.716 0 00-.716.716v.453a.287.287 0 01-.288.288H2.236z"/>
    <path d="M10.869 11.25a4.13 4.13 0 01-4.126-4.125A4.13 4.13 0 0110.87 3a4.13 4.13 0 014.124 4.125 4.13 4.13 0 01-4.124 4.125zm0-7.677a3.557 3.557 0 00-3.553 3.552 3.556 3.556 0 003.553 3.553 3.556 3.556 0 003.552-3.553 3.557 3.557 0 00-3.552-3.552z"/>
    <path d="M10.869 9.75a2.628 2.628 0 01-2.626-2.625A2.628 2.628 0 0110.87 4.5a2.628 2.628 0 012.624 2.625A2.628 2.628 0 0110.87 9.75zm0-4.7a2.078 2.078 0 00-2.076 2.075c0 1.145.931 2.076 2.076 2.076a2.078 2.078 0 002.075-2.076A2.078 2.078 0 0010.87 5.05zm4.5.2c-.62 0-1.126-.505-1.126-1.125S14.748 3 15.37 3a1.126 1.126 0 010 2.25zm0-1.705a.58.58 0 100 1.161.58.58 0 000-1.161zM3.522 12h-.307a.978.978 0 01-.972-.981V4.73c0-.54.436-.981.972-.981h.307c.536 0 .971.44.971.981v6.288a.978.978 0 01-.971.981zm-.307-7.682a.411.411 0 00-.41.413v6.288c0 .228.184.413.41.413h.307a.411.411 0 00.409-.413V4.73a.411.411 0 00-.41-.413h-.306zM5.718 2.25h-3.2c-.151 0-.275-.144-.275-.321v-.48C2.243.65 2.8 0 3.485 0h1.267c.685 0 1.241.65 1.241 1.45v.479c0 .177-.123.321-.275.321zm-2.924-.643h2.649V1.45c0-.445-.31-.806-.69-.806H3.484c-.381 0-.691.361-.691.806v.158z"/>
  </symbol>
  <symbol id="no-flash-icon" viewBox="0 0 18 18">
    <path d="M15.364 2.636A8.941 8.941 0 009 0a8.941 8.941 0 00-6.364 2.636A8.941 8.941 0 000 9a8.94 8.94 0 002.636 6.364A8.941 8.941 0 009 18a8.941 8.941 0 006.364-2.636A8.941 8.941 0 0018 9a8.941 8.941 0 00-2.636-6.364zm-.412.412A8.362 8.362 0 0117.417 9c0 2.15-.802 4.178-2.263 5.742l-3.573-3.574 1.205-2.01a.862.862 0 00.011-.878.862.862 0 00-.758-.442H9.344l.853-5.12a.575.575 0 00-.372-.641.575.575 0 00-.7.246L6.729 6.316l-3.47-3.47A8.357 8.357 0 019 .583c2.248 0 4.362.876 5.952 2.465zm-5.73 6.634A.292.292 0 009 9.58H5.961a.284.284 0 01-.251-.146.284.284 0 01.004-.291l1.13-1.885 4.002 4.002-2.466 4.11.909-5.452a.292.292 0 00-.066-.237zm-2.068-2.94L9.62 2.628 8.711 8.08a.292.292 0 00.288.34h3.039c.152 0 .226.102.251.146a.284.284 0 01-.004.291l-1.13 1.885L7.153 6.74zm-4.106 8.21A8.362 8.362 0 01.583 9c0-2.15.802-4.178 2.263-5.742L6.42 6.832l-1.205 2.01a.862.862 0 00-.011.878.86.86 0 00.758.442h2.695l-.853 5.12a.575.575 0 00.372.641.575.575 0 00.7-.247l2.396-3.992 3.47 3.47A8.357 8.357 0 019 17.417a8.362 8.362 0 01-5.952-2.465z"/>
  </symbol>
  <symbol id="sun-icon" viewBox="0 0 21 21">
    <path d="M10.5 16c2.768 0 5-2.25 5-5s-2.25-5-5-5-5 2.25-5 5 2.232 5 5 5zm0-8.554A3.551 3.551 0 0114.054 11a3.551 3.551 0 01-3.554 3.554A3.551 3.551 0 016.946 11 3.551 3.551 0 0110.5 7.446zm1-4.223V.777c0-.436-.439-.777-1-.777-.561 0-1 .341-1 .777v2.446c0 .436.439.777 1 .777.561 0 1-.341 1-.777zm-2 14.554v2.446c0 .436.439.777 1 .777.561 0 1-.341 1-.777v-2.446c0-.436-.439-.777-1-.777-.561 0-1 .341-1 .777zm6.214-11.985l1.578-1.578a.712.712 0 00-1.006-1.006l-1.578 1.578a.712.712 0 001.006 1.006zm-12.006 12a.695.695 0 00.503.208c.173 0 .364-.07.503-.208l1.578-1.578a.712.712 0 00-1.006-1.006l-1.578 1.578a.712.712 0 000 1.006zM20.5 11c0-.561-.341-1-.777-1h-2.446c-.436 0-.777.439-.777 1 0 .561.341 1 .777 1h2.446c.417 0 .777-.439.777-1zM1.277 12h2.446c.436 0 .777-.439.777-1 0-.561-.341-1-.777-1H1.277C.841 10 .5 10.439.5 11c0 .561.36 1 .777 1zm15.512 6a.712.712 0 00.503-1.214l-1.578-1.578a.712.712 0 00-1.006 1.006l1.578 1.578c.139.139.33.208.503.208zM6.292 5.792a.712.712 0 000-1.006L4.714 3.208a.712.712 0 00-1.006 1.006l1.578 1.578A.695.695 0 005.79 6a.7.7 0 00.503-.208z"/>
  </symbol>
  <symbol id="no-attributes-icon" viewBox="0 0 20 20">
    <g fill="none" fill-rule="evenodd">
      <path d="M7.164 10.482c.829.334 1.925.518 3.086.518 1.161 0 2.257-.184 3.087-.518.911-.367 1.413-.888 1.413-1.467 0-.32-.156-.79-.898-1.22a3.247 3.247 0 00-.402-.195l-.17-.07c-.131-1.339-.467-2.13-1.025-2.415-.638-.325-1.34.115-1.76.378-.08.05-.186.117-.245.146a3.7 3.7 0 01-.245-.146c-.42-.263-1.122-.703-1.76-.378-.558.285-.894 1.076-1.024 2.416l-.17.07a3.02 3.02 0 00-.403.195c-.742.428-.898.9-.898 1.219 0 .579.502 1.1 1.414 1.467zM8.5 5.627a.52.52 0 01.24-.054c.316 0 .683.23.967.408.232.145.384.24.543.24.159 0 .311-.095.543-.24.36-.226.853-.535 1.207-.354.255.13.58.577.715 1.949-.7.356-1.567.55-2.465.55-.899 0-1.766-.194-2.465-.55.135-1.372.46-1.819.715-1.949zM6.928 8.293c.1-.058.21-.11.334-.161l.198-.081c.79.419 1.775.649 2.79.649 1.015 0 2-.23 2.79-.65l.198.082c.124.05.233.103.334.161.395.228.612.485.612.722 0 .318-.395.668-1.057.935-.764.307-1.786.477-2.877.477-1.091 0-2.113-.17-2.877-.477-.662-.267-1.057-.617-1.057-.935 0-.237.217-.493.612-.722zm7.54 3.941h-.335C13.87 11.51 13.25 11 12.53 11c-.726 0-1.349.516-1.608 1.246a1.299 1.299 0 00-1.347.005C9.317 11.518 8.693 11 7.965 11c-.721 0-1.342.51-1.603 1.234h-.33c-.156 0-.282.145-.282.324 0 .18.126.325.282.325h.2A2.383 2.383 0 006.23 13c0 1.103.778 2 1.735 2 .957 0 1.736-.897 1.736-2l-.001-.073a.809.809 0 011.096-.01 2.225 2.225 0 00-.002.083c0 1.103.779 2 1.736 2s1.735-.897 1.735-2c0-.04 0-.078-.003-.117h.206c.156 0 .282-.145.282-.325 0-.179-.126-.324-.282-.324zM7.965 14.35c-.646 0-1.172-.606-1.172-1.351s.526-1.351 1.172-1.351c.647 0 1.173.606 1.173 1.351s-.526 1.351-1.173 1.351zm4.565 0c-.647 0-1.173-.606-1.173-1.351s.526-1.351 1.173-1.351c.646 0 1.172.606 1.172 1.351s-.526 1.351-1.172 1.351z" fill="currentColor" fill-rule="nonzero"/>
      <g stroke="currentColor" stroke-width=".6" transform="translate(1 1)">
        <circle cx="9" cy="9" r="9"/>
        <path stroke-linecap="square" d="M15.072 15.295L3.016 3.085"/>
      </g>
    </g>
  </symbol>
</svg>

<bc-translations></bc-translations>

<link [attr.href]="(theme.themeUrl$ | async) | safe" type="text/css" rel="stylesheet" *ngIf="(theme.themeUrl$ | async)">

<router-outlet></router-outlet>
