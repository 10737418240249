import { Component, Input, AfterViewInit, ElementRef } from '@angular/core';

@Component({
  selector: 'bc-message',
  template: '<ng-content></ng-content>'
})
export class BcMessageComponent implements AfterViewInit {
  @Input() key: string;
  translation: string;

  constructor(private el: ElementRef) {  }

  ngAfterViewInit() {
    this.translation = this.el.nativeElement.innerText;
  }
}
