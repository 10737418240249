<section *ngIf="(stepsStore.steps$ | async) as step">
  <div class="screen-title">
    <button type="button" class="screen-title__btn u-unstyled-btn" (click)="back()" *ngIf="!captureStarted">
      <svg class="svg-icon" width="6" height="12">
        <use xlink:href="#back-arrow-icon"></use>
      </svg>
    </button>
    <p *ngIf="isPassportUpload(step)" class="h3-header text-center" i18n="@@takePhotoYourPassport">
      Take a photo of your passport
    </p>
    <p *ngIf="!isPassportUpload(step)" class="h3-header text-center" i18n="@@takePhotoDocument">
      Take a photo of your document
    </p>
  </div>

  <ng-template [ngIf]="!errorStatus">
    <p class="h4-header h4-header_light text-center mb-2" *ngIf="isPassportUpload(step)" i18n="@@providePhoto">
      Please provide us with a good photo of your passport. Make sure both the photo and your personal information are visible,
      with no unreadable parts.
    </p>

    <ng-template [ngIf]="!isPassportUpload(step)">
      <p class="h4-header h4-header_light text-center mb-2" i18n="@@providePhotoIdentityCard">Please provide us with a good photo of your Identity Card. Make sure to capture both sides by not covering any parts.</p>
    </ng-template>
  </ng-template>

  <div class="h4-header h4-header_light text-center mb-2" *ngIf="errorStatus">
      <p>{{errorMessages}}</p>
      <p i18n="@@pleaseTryManualCapture">
        Please try to capture the documents manually instead.
      </p>
  </div>

  <ng-template [ngIf]="!errorStatus">
    <div class="text-center">
      <img src="./../../assets/images/tutorial_passport.gif" class="w-75" *ngIf="isPassportUpload(step)" />
      <img src="./../../assets/images/tutorial_dl_front.gif" class="w-75" *ngIf="isIdFrontSideUpload(step)" />
      <img src="./../../assets/images/tutorial_dl_back.gif" class="w-75" *ngIf="isIdBackSideUpload(step)" />
    </div>

    <div class="mt-2 d-flex justify-content-center">
      <div>
        <div class="none">
          <svg class="svg-icon" width="21" height="21">
            <use xlink:href="#sun-icon"></use>
          </svg>
          <p class="main-text main-text_small d-inline-block ml-2 mb-3" i18n="@@useALightRoon">Use a light room</p>
        </div>
        <div class="none">
          <svg class="svg-icon" width="21" height="21">
            <use xlink:href="#no-flash-icon"></use>
          </svg>
          <p class="main-text main-text_small d-inline-block ml-2 mb-3" i18n="@@noFlash">No flash</p>
        </div>
        <div class="none">
          <svg class="svg-icon" width="21" height="21">
            <use xlink:href="#photo-icon"></use>
          </svg>
          <p class="main-text main-text_small d-inline-block ml-2 mb-3" i18n="@@holdStable">Hold the camera stable</p>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="mt-3 mb-5 text-center">

    <button type="button" class="btn bc-btn btn-custom screen_btn margin-bot-10"
            [disabled]="captureStarted" (click)="captureDocument()">
      <ng-container *ngIf="captureStarted" i18n="@@loading">Loading...</ng-container>

      <ng-template [ngIf]="!errorStatus && !captureStarted">
        <ng-container *ngIf="isIdBackSideUpload(step)" i18n="@@takePhotoBackSide">Take a photo of the back side</ng-container>
        <ng-container *ngIf="isIdFrontSideUpload(step)" i18n="@@takePhotoFrontSide">Take a photo of the front side</ng-container>
        <ng-container *ngIf="isPassportUpload(step)" i18n="@@takePhoto">Take a photo</ng-container>
      </ng-template>

      <ng-container *ngIf="!captureStarted && errorStatus" i18n="@@manualCapture">Manual capture</ng-container>
    </button>
  </div>
</section>
