import { Component } from '@angular/core';

import { StepsStoreService } from '../core/services';

import { DocumentTypes, IdType } from '../models/documentType';
import { IdentificationSteps } from '../models/identificationSteps';

@Component({
  selector: 'app-success-capture',
  templateUrl: './success-capture.component.html',
  styleUrls: ['./success-capture.component.scss']
})
export class SuccessCaptureComponent {
  documentTypes = DocumentTypes;
  idTypes = IdType;

  steps = IdentificationSteps;

  constructor(public stepsStore: StepsStoreService) { }

  next() {
    if (this.stepsStore.step.idSide === IdType.FRONT) {
      this.stepsStore.step = {
        ...this.stepsStore.step,
        idSide: IdType.BACK
      };
    } else {
      this.stepsStore.next = this.stepsStore.step.selfieRequired ? IdentificationSteps.SELFIE : IdentificationSteps.OVERVIEW;
    }

    this.stepsStore.step.success = false;
  }

}
