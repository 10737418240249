<bc-catch-messages [manager]="mitekMessagesManager">
  <!-- Mitek selfie messages -->
  <bc-message key="NO_FACE_FOUND" i18n="@@noFaceFound">No face detected</bc-message>
  <bc-message key="MISNAP_HEAD_SKEWED" i18n="@@headSkewed">Look straight ahead</bc-message>
  <bc-message key="MISNAP_HEAD_TOO_CLOSE" i18n="@@headTooClose">Move further away</bc-message>
  <bc-message key="MISNAP_HEAD_TOO_FAR" i18n="@@headTooFar">Get closer</bc-message>
  <bc-message key="MISNAP_AXIS_ANGLE" i18n="@@wrongAxisAngel">Hold phone straight</bc-message>
  <bc-message key="MISNAP_HEAD_OUTSIDE" i18n="@@headOutside">Place face in oval</bc-message>
  <bc-message key="MISNAP_SMILE" i18n="@@smile">Smile!</bc-message>
  <bc-message key="MISNAP_STAY_STILL" i18n="@@stayStill">Hold still</bc-message>
  <bc-message key="MISNAP_STOP_SMILING" i18n="@@stopSmiling">Stop smiling</bc-message>
  <bc-message key="MISNAP_READY_POSE" i18n="@@readyPose">Hold it there</bc-message>
  <bc-message key="MISNAP_TOO_DARK" i18n="@@tooDark">Not enough light</bc-message>
  <bc-message key="MISNAP_TOO_BLURRY" i18n="@@adjustFocus">Adjust focus</bc-message>
  <bc-message key="MISNAP_SUCCESS" i18n="@@success">Success!</bc-message>

  <!-- UI Hints -->
  <bc-message key="MITEK_ERROR_FOCUS" i18n="@@adjustFocus">Adjust focus</bc-message>
  <bc-message key="MITEK_ERROR_FOUR_CORNER" i18n="@@docFourCorner">Document is not within the four corners</bc-message>
  <bc-message key="MITEK_ERROR_GLARE" i18n="@@reduceGlare">Reduce Glare</bc-message>
  <bc-message key="MITEK_ERROR_HORIZONTAL_FILL" i18n="@@documentCoverTooSmall">Document covers less than 70% of the placeholder</bc-message>
<!--  <bc-message key="MITEK_ERROR_LOW_CONTRAST" i18n="@@lowContrastError">There is insufficient contrast</bc-message>-->
  <bc-message key="MITEK_ERROR_LOW_CONTRAST" i18n="@@centerDocumentOnDarkBack">Place document on a dark background</bc-message>
  <bc-message key="MITEK_ERROR_BUSY_BACKGROUND" i18n="@@busyBackgroundError">Use a single-color contrasting back drop</bc-message>
  <bc-message key="MITEK_ERROR_MIN_PADDING" i18n="@@documentPaddingError">Document too close to the edges of the frame</bc-message>
  <bc-message key="MITEK_ERROR_SKEW_ANGLE" i18n="@@errorSkewAngel">Document skewed</bc-message>
  <bc-message key="MITEK_ERROR_TOO_DARK" i18n="@@imageTooDark">Image too dark</bc-message>

  <bc-message key="MITEK_ERROR_MRZ_MISSING" i18n="@@mrzZone">Machine readable zone missing</bc-message>

  <bc-message key="CV_NO_BARCODE_FOUND" i18n="@@noBarcode">No barcode found</bc-message>

  <bc-message key="CORRUPT_IMAGE" i18n="@@corruptImage">Corrupt image</bc-message>
  <bc-message key="IMAGE_SMALLER_THAN_MIN_SIZE" i18n="@@docTooSmall">Document too small</bc-message>

<!--  <bc-message key="MITEK_INITIAL_HINT" i18n="@@fillGuideWindow">Fill Guide Window</bc-message>-->
  <bc-message key="MITEK_INITIAL_HINT" i18n="@@centerDocumentOnDarkBack">Place document on a dark background</bc-message>
  <bc-message key="MITEK_FOUR_CORNER_MESSAGE" i18n="@@centerDocumentOnDarkBack">Place document on a dark background</bc-message>
  <bc-message key="MITEK_INITIAL_HINT_SELFIE" i18n="@@headOutside">Place face in oval</bc-message>
  <bc-message key="MITEK_FOUR_CORNER_MESSAGE_SELFIE" i18n="@@noFaceFound">No face detected</bc-message>

  <!-- Compatibility Errors  -->
  <bc-message key="CONSTRAINT_NOT_SATISFIED" i18n="@@cameraResolutionError">The video camera runs at 1280x720. You might be on an older device that does not support this resolution.</bc-message>
  <bc-message key="NO_CAMERA_FOUND" i18n="@@noCamera">No video camera was found</bc-message>
  <bc-message key="CAMERA_PERMISSION_DENIED" i18n="@@allowError">Unfortunately we were not able to access your camera.</bc-message>
  <bc-message key="CAMERA_UNKNOWN_DEVICE_ISSUE" i18n="@@cameraError">Unable to start the camera / unknown error</bc-message>
  <bc-message key="UNKNOWN_METHOD" i18n="@@cameraError">Unable to start the camera / unknown error</bc-message>
  <bc-message key="INVALID_COMMAND_SIGNATURE" i18n="@@cameraError">Unable to start the camera / unknown error</bc-message>
  <bc-message key="USER_MEDIA_NOT_SUPPORTED" i18n="@@unsupportedBrowser2">Unsupported browser: An older browser version, Media not supported</bc-message>
  <bc-message key="WASM_NOT_SUPPORTED" i18n="@@unsupportedBrowser">Unsupported browser</bc-message>
  <bc-message key="DEVICE_NOT_SUPPORTED" i18n="@@unsupportedBrowser">Unsupported browser</bc-message>
  <bc-message key="WEBGL_NOT_SUPPORTED" i18n="@@unsupportedBrowser">Unsupported browser</bc-message>
  <bc-message key="WEBGL_SHADER_NOT_SUPPORTED" i18n="@@unsupportedBrowser">Unsupported browser</bc-message>

  <bc-message key="SDK_NOT_INITIALIZED" i18n="@@cameraError">Unable to start the camera / unknown error</bc-message>
  <bc-message key="ERROR_CAMERA_STUCK" i18n="@@errorCameraStuck">An error has occurred while attempting to access your camera. Please refresh this page or try closing the tabs for this app.</bc-message>
  <bc-message key="CAMERA_TIMEOUT" i18n="@@cameraTimeOut">You took longer than expected.</bc-message>
  <bc-message key="ERROR" i18n="@@unexpectedErrorShort">There was an unexpected error.</bc-message>

  <bc-message key="SELFIE_ERROR" i18n="@@unableToCaptureSelfie">Unable to read the capture of your Selfie. Errors: </bc-message>

  <bc-message key="LOADING" i18n="@@pleaseWait">Please wait</bc-message>
  <bc-message key="LOADING_TITLE" i18n="@@loading">Loading</bc-message>
</bc-catch-messages>
