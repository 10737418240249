<section *ngIf="(stepsStore.steps$ | async) as step">
  <div class="screen-title">
    <button type="button" class="screen-title__btn u-unstyled-btn" (click)="back()">
      <svg class="svg-icon" width="6" height="12">
        <use xlink:href="#back-arrow-icon"></use>
      </svg>
    </button>
    <p class="h3-header text-center" i18n="@@takePhotoYourself">Take a photo of yourself</p>
  </div>

  <p class="h4-header h4-header_light text-center mb-2" i18n="@@takePhotoYourselfDescription">
    Please provide us with a good photo of yourself. Make sure to hold
    device at eye level, to center your face and smile when you
    are ready.
  </p>

  <div class="text-center mb-1">
    <svg class="svg-icon svg-icon_colored" width="71" height="122">
      <use xlink:href="#selfie-long-icon"></use>
    </svg>
  </div>

  <div class="mt-2 d-flex justify-content-center">
    <div>
      <div class="none">
        <svg class="svg-icon" width="21" height="21">
          <use xlink:href="#sun-icon"></use>
        </svg>
        <p class="main-text main-text_small d-inline-block ml-2" i18n="@@useALightRoon">Use a light room</p>
      </div>
      <div class="none">
        <svg class="svg-icon" width="21" height="21">
          <use xlink:href="#no-flash-icon"></use>
        </svg>
        <p class="main-text main-text_small d-inline-block ml-2 mb-3" i18n="@@noFlash">No flash</p>
      </div>
      <div class="none">
        <svg class="svg-icon" width="21" height="21">
          <use xlink:href="#photo-icon"></use>
        </svg>
        <p class="main-text main-text_small d-inline-block ml-2 mb-3" i18n="@@holdStable">Hold the camera stable</p>
      </div>
      <div class="none">
        <svg class="svg-icon" width="21" height="21">
          <use xlink:href="#no-attributes-icon"></use>
        </svg>
        <p class="main-text main-text_small d-inline-block ml-2 mb-3" i18n="@@noHatsGlass">No hat or glasses</p>
      </div>
    </div>
  </div>

  <div class="mt-3 mb-5 text-center">
    <button type="button" class="btn bc-btn btn-custom screen_btn" [disabled]="captureStarted" (click)="captureSelfie()">
      <ng-container *ngIf="!captureStarted" i18n="@@takePhotoYourself">Take a photo of yourself</ng-container>
      <ng-container *ngIf="captureStarted" i18n="@@loading">Loading...</ng-container>
    </button>
  </div>
</section>
