export enum MitekScienceCommands {
  // 'cmd' commands
  CAPTURE_AND_PROCESS_FRAME = 'CAPTURE_AND_PROCESS_FRAME',
  SDK_STOP = 'SDK_STOP',
  SHOW_HINT = 'SHOW_HINT',
  HIDE_HINT = 'HIDE_HINT',

  // event types 'on'
  SDK_ERROR = 'SDK_ERROR',
  CAMERA_DISPLAY_STARTED = 'CAMERA_DISPLAY_STARTED',
  FRAME_PROCESSING_STARTED = 'FRAME_PROCESSING_STARTED',
  FRAME_PROCESSING_FEEDBACK = 'FRAME_PROCESSING_FEEDBACK',
  FRAME_CAPTURE_RESULT = 'FRAME_CAPTURE_RESULT',
  IMAGE_CAPTURED = 'IMAGE_CAPTURED'  // not-used for now
}
