/* eslint-disable */
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../../../environments/environment';
import { BcErrorHandler } from '../common/bugsnag/bc-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class Logger {

  constructor(private toastr: ToastrService, private bcErrorHandler: BcErrorHandler) {  }

  log(message: string, title?: string) {
    if (environment.ENV === 'development' || environment.ENV === 'local') {
      const msg = title ? `${title  }: ${  message}` : message;
      console.log(msg);
    }
  }

  info(message: string, title?: string) {
    if (environment.ENV === 'development' || environment.ENV === 'local') {
      const msg = title ? `${title  }: ${  message}` : message;
      console.info(msg);
    }
  }

  warn(message: string, title?: string) {
    if (environment.ENV === 'development' || environment.ENV === 'local') {
      const msg = title ? `${title  }: ${  message}` : message;
      console.warn(msg);
    }
  }

  error(message: string, title?: string) {
    const msg = title ? `${title  }: ${  message}` : message;
    if (environment.ENV === 'development' || environment.ENV === 'local') {
      console.error(msg);
    }
    this.bcErrorHandler.reportHandledError(msg);
  }

  showError(error: any) {
    const msg = error ? error.message || error.errorMessage || error : null;
    if (environment.ENV === 'development' || environment.ENV === 'local') {
      console.log('-------------------------------------------------');
      console.error('error: ', error);
      console.log('-------------------------------------------------');
      this.toastr.error(msg, 'Error!');
    } else {
      this.toastr.error(msg || 'Something went wrong', 'Error!');
    }
    this.bcErrorHandler.reportHandledError(msg);
  }
}
