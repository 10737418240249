import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

const baseApi = environment.BASE_API;

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }

  saveDocument(document: any, id: string): Observable<any> {
    const key = sessionStorage.getItem('k');
    const header = new HttpHeaders({'Content-Type': 'application/json'});

    return this.http.post(`${baseApi}/documents/${id}?k=${key}`, document, {headers: header});
  }

  sendComplete(document: any, id: string): Observable<any> {
    const key = sessionStorage.getItem('k');
    const header = new HttpHeaders({'Content-Type': 'application/json'});

    return this.http.post(`${baseApi}/documents/${id}?action=SEND&k=${key}`, document, {headers: header});
  }

}
