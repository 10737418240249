
<section class="mt-5">
  <div class="d-flex justify-content-center my-4">
    <svg class="svg-icon success-icon" width="80" height="80">
      <use xlink:href="#success-icon"></use>
    </svg>
  </div>

  <p class="h1-header h1-header_light text-center success mb-3" i18n="@@yourIdentification">Your identification</p>
  <p class="h2-header h2-header_light text-center finished mb-5" i18n="@@yourIdentification2">has been finished successfully!</p>
  <p class="h2-header h2-header_light text-center finished mt-3" i18n="@@redirected">You are being redirected...</p>
</section>

