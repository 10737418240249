import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { ToastrModule } from 'ngx-toastr';

import { BcBugSnagModule } from './core/common/bugsnag/bugsnag.module';
import { TranslationsModule } from './translations/translations.module';

import { AppRoutingModule } from './app-routing.module';

import { SafePipe } from './safe.pipe';
import { BcCatchMessagesModule, BcMessagesManager } from './widgets';

import { AppComponent } from './app.component';
import { LayoutComponent } from './core/components/layout/layout.component';
import { HeaderComponent } from './core/components/header/header.component';
import { LoadingComponent } from './shared/components/loading/loading.component';

import { LandingScreenComponent } from './landing-screen/landing-screen.component';
import { CaptureScreenComponent } from './capture-screen/capture-screen.component';
import { SuccessCaptureComponent } from './success-capture/success-capture.component';
import { SelectDocumentComponent } from './select-document/select-document.component';
import { DocumentCaptureComponent } from './document-capture/document-capture.component';
import { SelfieCaptureComponent } from './selfie-capture/selfie-capture.component';
import { OverviewComponent } from './overview/overview.component';
import { PreviewDocumentComponent } from './preview-document/preview-document.component';
import { IdentificationCompleteComponent } from './identification-complete/identification-complete.component';

import { InvalidUrlComponent } from './invalid-url/invalid-url.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LandingScreenComponent,
    CaptureScreenComponent,
    HeaderComponent,
    SuccessCaptureComponent,
    SelectDocumentComponent,
    LoadingComponent,
    IdentificationCompleteComponent,
    InvalidUrlComponent,
    DocumentCaptureComponent,
    SelfieCaptureComponent,
    OverviewComponent,
    PreviewDocumentComponent,
    SafePipe,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BcCatchMessagesModule,
    BcBugSnagModule,
    TranslationsModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      positionClass: 'toast-bottom-full-width'
    })
  ],
  providers: [BcMessagesManager],
  bootstrap: [AppComponent]
})
export class AppModule { }

