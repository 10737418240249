import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { Logger } from './logger.service';
import { UserStateService } from './user-state.service';

import { IdentificationDetails } from '../../models/identificationDetails';
import { ThemeService } from './theme.service';

import { environment } from '../../../environments/environment';
import { StepsStoreService } from './steps-store.service';
const baseApi = environment.BASE_API;


@Injectable({
  providedIn: 'root'
})
export class IdentificationService  {
  identificationDetails: any;

  constructor(private http: HttpClient,
              private logger: Logger,
              private userState: UserStateService,
              private stepsStore: StepsStoreService,
              private themeService: ThemeService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IdentificationDetails> {
    if (this.identificationDetails) {
      return Promise.resolve(this.identificationDetails);
    }

    const id = route.queryParams.id || sessionStorage.getItem('userToken');
    const key = route.queryParams.k || sessionStorage.getItem('k');

    if (!id) {
      this.logger.error('token_missing');

      return Promise.reject('token_missing');
    }

    sessionStorage.setItem('k', key);
    sessionStorage.setItem('userToken', id);

    this.userState.userToken.next(id);
    if (!this.stepsStore.step.id) this.stepsStore.step.id = id;

    return this.getDetails(id, key).toPromise();
  }

  private getDetails(token: string, k: string) {
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };

    return this.http.get(`${baseApi}/basicinfo/${token}?k=${k}`, options).pipe(
      tap((res: IdentificationDetails) => {
        this.identificationDetails = res;
        this.userState.user.next({
          firstName: res.firstName,
          lastName: res.lastName,
          token: this.userState.userToken.value
        });

        this.stepsStore.step = {
          ...this.stepsStore.step,
          selfieRequired: res.numberOfSelfies > 0,
          allowDriversLicense: res.allowDriversLicense,
          allowId: res.allowId
        };

        this.themeService.themeUrl$.next(res.cssUrl ? res.cssUrl : 'assets/themes/default/styles.css');

        return res;
      }),
      catchError((res: HttpErrorResponse) => {
        this.logger.error(res.error.errorMessage);

        return throwError(res.error.errorMessage);
      })
    );
  }
}
