import { Component, Inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { Logger, StepsStoreService } from '../core/services';

import { BcMessagesManager } from '../widgets';
import { MITEK_MESSAGES_MANAGER } from '../translations/translation.injectors';

import { DocumentTypes, DocumentTypesShortcat, ImageTypes } from '../models/documentType';

import { MitekScienceCommands } from '../models/mitekScienceCommands';
import { MitekResultStatus, MitekFeedbackMessages } from '../models/mitekStatuses';
import { IdentificationSteps } from '../models/identificationSteps';

import { MitekMode } from '../models/mitekMode';
import { MitekConfig } from '../shared/mitek-config';


@Component({
  selector: 'app-selfie-capture',
  templateUrl: './selfie-capture.component.html',
  styleUrls: ['./selfie-capture.component.scss']
})
export class SelfieCaptureComponent {
  cameraDisplayStarted: boolean;
  cameraProcessingStarted: boolean;
  captureStarted: boolean;
  timer: any;

  private readonly captureProcessLength = 20000; // 20 seconds
  private readonly cameraErrorTimeout = 30000;

  constructor(
    @Inject(MITEK_MESSAGES_MANAGER) public mitekMessagesManager: BcMessagesManager,
    public stepsStore: StepsStoreService,
    private logger: Logger,
    private router: Router,
    private toastr: ToastrService,
    private ngZone: NgZone
  ) { }

  back() {
    this.stepsStore.next = IdentificationSteps.DOCUMENT;
  }

  captureSelfie() {
    this.captureStarted = true;
    this.cameraDisplayStarted = false;
    this.cameraProcessingStarted = false;

    this.ngZone.runOutsideAngular(() => {
      this.initCapture();
    });
  }

  initCapture() {
    // may be needed to address new browser standards (explicit video play)
    const videoElement: HTMLMediaElement = document.querySelector('div video[autoplay="true"]');
    videoElement.play();

    setTimeout(() => {
      if (!this.cameraProcessingStarted || !this.cameraDisplayStarted) {
        window.mitekScienceSDK.cmd(MitekScienceCommands.SDK_STOP);

        const errorMessage = this.mitekMessagesManager.translations['ERROR_CAMERA_STUCK'];
        this.ngZone.run(() => {
          this.logger.error(`cameraProcess: ${this.cameraProcessingStarted}, cameraDisplay: ${this.cameraProcessingStarted}`, 'Camera error');
          this.toastr.info(errorMessage, '');
        });
      }
    }, this.cameraErrorTimeout);

    // SDK READY:
    window.mitekScienceSDK.on(MitekScienceCommands.CAMERA_DISPLAY_STARTED, (result) => {
      this.ngZone.run(() => {
        this.cameraDisplayStarted = true;

        this.toastr.info(this.mitekMessagesManager.translations['LOADING'], this.mitekMessagesManager.translations['LOADING_TITLE']);
      });

      window.mitekScienceSDK.cmd(MitekScienceCommands.SHOW_HINT, this.mitekMessagesManager.translations['MITEK_INITIAL_HINT_SELFIE']);
    });

    // SDK READY:
    window.mitekScienceSDK.on(MitekScienceCommands.FRAME_PROCESSING_STARTED, (result) => {
      this.ngZone.run(() => {
        this.cameraProcessingStarted = true;
        this.toastr.clear();
      });

      // timeout after 20 seconds
      this.timer = setTimeout(() => this.stopAutoCapture(), this.captureProcessLength);
    });

    // CAPTURE CHANNEL: returns { request: us_front, response: [frameResult]}
    window.mitekScienceSDK.on(MitekScienceCommands.FRAME_CAPTURE_RESULT, this.frameCaptureResult.bind(this));

    window.mitekScienceSDK.on(MitekScienceCommands.SDK_ERROR, (err) => {
      this.ngZone.run(() => {
        this.captureStarted = false;

        const errorMessages = err.map(error => this.mitekMessagesManager.translations[error.type]).join(' ');
        this.logger.error(err.map(error => error.type).join(' '), MitekScienceCommands.SDK_ERROR);
        this.toastr.info(errorMessages, '');
      });
    });

    // CAPTURE FEEDBACK CHANNEL: during capture, feedback for frames that...i.e. Hint Messages
    window.mitekScienceSDK.on(MitekScienceCommands.FRAME_PROCESSING_FEEDBACK, (result) => {
      const recentHint = result.key;
      const selfieWrapper = document.getElementsByClassName('integrator SELFIE')[0];

      if (!selfieWrapper) {
        this.logger.error(`No 'integrator SELFIE' element found`, MitekScienceCommands.FRAME_PROCESSING_FEEDBACK);
      } else {
        // turn oval green if head is in guide
        if (recentHint === MitekFeedbackMessages.MISNAP_SMILE ||
          recentHint === MitekFeedbackMessages.MISNAP_STOP_SMILING ||
          recentHint === MitekFeedbackMessages.MISNAP_READY_POSE) {
          selfieWrapper.classList.add('FACE_IN_GUIDE');
        } else {
          selfieWrapper.classList.remove('FACE_IN_GUIDE');
        }
      }

      if (recentHint) {
        const hintMessages = recentHint === MitekFeedbackMessages.MITEK_ERROR_FOUR_CORNER ?
          this.mitekMessagesManager.translations['MITEK_FOUR_CORNER_MESSAGE_SELFIE'] :
          this.mitekMessagesManager.translations[recentHint];

        window.mitekScienceSDK.cmd(MitekScienceCommands.SHOW_HINT, hintMessages);
      }
    });

    window.mitekScienceSDK.cmd(MitekScienceCommands.CAPTURE_AND_PROCESS_FRAME, {
      ...MitekConfig,
      ...{
        mode: MitekMode.AUTO_CAPTURE,
        documentType: DocumentTypes.Selfie
      }
    });
  }

  stopAutoCapture() {
    this.ngZone.run(() => {
      this.captureStarted = false;

      this.toastr.info(this.mitekMessagesManager.translations['CAMERA_TIMEOUT'], '');
    });

    window.mitekScienceSDK.cmd(MitekScienceCommands.SDK_STOP);
  }

  private frameCaptureResult(result) {
    this.ngZone.run(() => {
      this.captureStarted = false;
      clearTimeout(this.timer);

      const resultStatus = result.response.status;
      if (resultStatus === MitekResultStatus.Failure) return;

      this.stepsStore.step.previewImage = {
        documentType: this.stepsStore.step.documentType === DocumentTypes.Passport ? DocumentTypesShortcat.Passport : DocumentTypesShortcat.ID,
        imageType: ImageTypes.Selfie,
        imageData: result.response.imageData
      };
      this.stepsStore.step.success = true;
    });

    window.mitekScienceSDK.cmd(MitekScienceCommands.SDK_STOP);
  }
}
