import { Component, Input, Output, EventEmitter, ContentChildren, QueryList, AfterViewInit } from '@angular/core';
import { BcMessageComponent } from './bc.catch-message.component';
import { BcMessagesManager } from './bc.messages-manager.service';

@Component({
  selector: 'bc-catch-messages',
  template: '',
  viewProviders: [BcMessageComponent],
  styles: [':host {display: none}']
})
export class BcMessagesComponent implements AfterViewInit {
  @Input() manager: BcMessagesManager;
  @Output() finished = new EventEmitter();
  @ContentChildren(BcMessageComponent) messages: QueryList<BcMessageComponent>;

  constructor() {  }

  ngAfterViewInit() {
    this.messages
      .filter((el: BcMessageComponent) => el.key !== undefined)
      .forEach((el: BcMessageComponent) => this.manager.add(el.key, el.translation));

    this.finished.emit();
  }

}
