<section class="text-center">
  <div class="d-flex justify-content-center mt-5 mb-5">
    <svg class="svg-icon" width="90" height="70">
      <use xlink:href="#expired-icon"></use>
    </svg>
  </div>

  <p class="mb-5 h2-header" i18n="@@identificationError">Your identification request has expired or is no longer valid.</p>

  <p class="mt-2 h3-header h3-header_light">
    <ng-container i18n="@@identificationErrorText1">Login via </ng-container>
    <strong i18n="@@identificationErrorText2">become a customer/open an account</strong>
    <ng-container i18n="@@identificationErrorText3"> and<br> restart the identification process.</ng-container>
  </p>

  <p class="mt-3 main-text main-text_warm-grey" i18n="@@contactPerson">
    For questions please contact your contact person.
  </p>
</section>
